import { weakMapMemoize } from 'reselect';

import { constructMSAImageUrl } from 'helpers/index';
import { toUSD } from 'helpers/NumberFormats';
import { HEART_DEFAULT_LIST_ID } from 'constants/apis';
import type { HeartProductDetailProps, HeartProps, UnHeartProductDetailProps } from 'types/hearts';

interface GetHeartPropsParams {
  heartProduct: (heartProps: HeartProductDetailProps) => void;
  unHeartProduct: (productId: UnHeartProductDetailProps) => void;
  toggleHeartingLoginModal: (show: boolean, styleId: string) => void;
  trackEvent: (eventName: string, eventData?: string) => void;
  hasHearting?: boolean;
  isCustomer?: boolean;
  hearts?: string[];
  products?: Record<string, any> /** @todo - add types to products values */;
  isDisplayCount?: boolean;
}

type MakeHandlerHeartButtonClickParams = {
  isHearted: boolean;
  onHeartClick: (params: { styleId: string; productId: string; price: number }, isHearted: boolean) => void;
  onCollectionToggle: ((show: boolean) => void) | undefined;
  productId: string;
  style: {
    styleId: string;
    basePrice: number;
    salePrice: number;
  };
};

export type HeartListOrCollection = {
  listId: string;
  shareToken: string;
  createTime: number;
  updateTime: number;
  itemCount: number;
  metadata: {
    published: boolean;
    headerLayout: number;
    images: { approved: boolean; feedback: unknown }[];
  };
};

export interface HeartEventData {
  heartEventName: string;
  unHeartEventName: string;
}

const makeHeartClickHandlerInternal = weakMapMemoize(
  (
    isCustomer: boolean | undefined,
    unHeartProduct: (productId: UnHeartProductDetailProps) => void,
    trackEvent: (eventName: string, eventData?: string) => void,
    unHeartEventName: string,
    heartProduct: (heartProps: HeartProductDetailProps) => void,
    heartEventName: string,
    toggleHeartingLoginModal: (show: boolean, styleId: string) => void
  ) =>
    function ({ styleId }: { styleId: string }, isHearted: boolean) {
      if (isCustomer) {
        if (isHearted) {
          unHeartProduct({ itemId: styleId } as UnHeartProductDetailProps);
          trackEvent(unHeartEventName, styleId);
        } else {
          heartProduct({ itemId: styleId } as HeartProductDetailProps);
          trackEvent(heartEventName, styleId);
        }
      } else {
        toggleHeartingLoginModal(true, styleId);
      }
    }
);

export function makeHeartClickHandler(heartEventData: HeartEventData, clickHandlerDataAndActions: GetHeartPropsParams) {
  const { heartEventName, unHeartEventName } = heartEventData;
  const { heartProduct, unHeartProduct, toggleHeartingLoginModal, trackEvent, isCustomer } = clickHandlerDataAndActions;
  return makeHeartClickHandlerInternal(
    isCustomer,
    unHeartProduct,
    trackEvent,
    unHeartEventName,
    heartProduct,
    heartEventName,
    toggleHeartingLoginModal
  );
}

export function isProductHearted(showFavoriteHeart: boolean, hearts: string[] = [], styleId: string) {
  return !!(showFavoriteHeart && styleId && hearts?.indexOf(styleId) > -1);
}

export function makeHandleHeartButtonClick({ isHearted, onHeartClick, onCollectionToggle, productId, style }: MakeHandlerHeartButtonClickParams) {
  return (e: Event) => {
    const { styleId, basePrice, salePrice } = style;
    const price = basePrice !== salePrice ? salePrice : basePrice;

    // Prevent incorrect tracking events and going to product url
    e.stopPropagation();
    e.preventDefault();
    // Run hearting callback if present
    if (onHeartClick) {
      onCollectionToggle && onCollectionToggle(!isHearted); // show collection if product has not been hearted prior to clicking "Heart"
      onHeartClick(
        {
          styleId,
          productId,
          price
        },
        isHearted
      );
    }
  };
}

// combines list object information with the items
export function makeCollectionInformationWithItems(lists: HeartListOrCollection[], collections: HeartListOrCollection[]) {
  const collectionWithItems: HeartListOrCollection[] = [];
  if (lists?.length && collections?.length) {
    lists.forEach(list => {
      const correspondingCollection = collections.find(collection => list.listId === collection.listId);
      collectionWithItems.push({
        ...list,
        ...correspondingCollection
      });
    });
  }

  return collectionWithItems;
}

export function makePublishedCollectionInformationWithItems(lists: HeartListOrCollection[], collections: HeartListOrCollection[]) {
  const collectionWithItems: HeartListOrCollection[] = [];
  if (lists?.length && collections?.length) {
    lists.forEach(list => {
      const correspondingPublishedCollection = collections.find(collection => list.shareToken === collection.shareToken);
      collectionWithItems.push({
        ...list,
        ...correspondingPublishedCollection
      });
    });
  }
  return collectionWithItems;
}

/**
 * internal implementation of getHeartProps with flattened params for memoization.
 */
const getHeartPropsInternal = weakMapMemoize(
  (
    heartProduct: any,
    isCustomer: any,
    toggleHeartingLoginModal: any,
    trackEvent: any,
    unHeartProduct: any,
    heartEventName: string,
    unHeartEventName: string,
    isDisplayCount: any
  ): HeartProps => {
    const clickHandlerDataAndActions = {
      heartProduct,
      isCustomer,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct
    };
    return {
      showFavoriteHeart: true,
      onHeartClick: makeHeartClickHandler({ heartEventName, unHeartEventName }, clickHandlerDataAndActions),
      isDisplayCount
    };
  }
);

const getHeartPropsNoHeartDefault = { showFavoriteHeart: false };
export function getHeartProps(heartProps: GetHeartPropsParams, { heartEventName, unHeartEventName }: HeartEventData) {
  const { hasHearting, isCustomer, heartProduct, toggleHeartingLoginModal, trackEvent, unHeartProduct, isDisplayCount } = heartProps || {};
  if (hasHearting) {
    return getHeartPropsInternal(
      heartProduct,
      isCustomer,
      toggleHeartingLoginModal,
      trackEvent,
      unHeartProduct,
      heartEventName,
      unHeartEventName,
      isDisplayCount
    );
  }

  return getHeartPropsNoHeartDefault;
}

export function formatHeartsForMelodyCard(heart: { imageId: string; price: number | string }) {
  const { imageId, price } = heart;
  return {
    ...heart,
    thumbnailImageId: imageId,
    thumbnailImageUrl: constructMSAImageUrl(imageId, {
      autoCrop: true,
      width: 272
    }),
    price: typeof price === 'number' ? toUSD(price) : price
  };
}

export const isDefaultHeartsPresent = (lists: { listId: string }[]) => lists.some(({ listId }) => listId === HEART_DEFAULT_LIST_ID);
